const buildEnv = process.env;

const getQueryStringValue = key =>
  decodeURIComponent(
    window.location.search.replace(
      new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[.+*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'),
      '$1'
    )
  );

export const getEnvURL = env => {
  const envPath = env !== 'prod' ? `-${env}` : '';
  return `https://workbench-us${envPath}.lower-pwclabs.pwcglb.com`;
};

const getEnv = () => {
  if (window.location.hostname === 'localhost') {
    const { NODE_ENV } = process.env;
    return !NODE_ENV || NODE_ENV === 'development' ? 'dev' : NODE_ENV;
  }

  const isEnv = env => window.location.hostname.indexOf(`-${env}`) !== -1;
  if (isEnv('dev')) {
    return 'dev';
  }
  if (isEnv('qa')) {
    return 'qa';
  }
  if (isEnv('stg')) {
    return 'stg';
  }
  if (isEnv('uat')) {
    return 'uat';
  }

  return 'prod';
};

let BASE_ENDPOINT = getQueryStringValue('WB_URL');

if (BASE_ENDPOINT) {
  localStorage.setItem('WB_URL', BASE_ENDPOINT);
} else {
  BASE_ENDPOINT = localStorage.getItem('WB_URL');
}

export const enabledEnvironment = () => {
  const isEnv = env => window.location.hostname.indexOf(`-${env}`) !== -1;
  if (isEnv('dev')) {
    return true;
  } else if (isEnv('qa')) {
    return true;
  }
  return false;
};

export const PWC_ENV = getEnv();

const getURL = () =>
  window.location.hostname === 'localhost' ? getEnvURL('dev') : `${window.location.protocol}//${window.location.host}`;

export const API_ENDPOINT = buildEnv.REACT_APP_WB_URL || BASE_ENDPOINT || getURL();

export const OAUTH_URL = `${API_ENDPOINT}/oauth/login?redirectUrl=`;
export const OAUTH_LOGOUT_URL = `${API_ENDPOINT}/oauth/logout`;
export const ROUTE_PREFIX = buildEnv.REACT_APP_ROUTE_PREFIX || '';
export const { REACT_APP_TYPE } = buildEnv;
export const IDLE_SESSION_TIMEOUT_SECONDS = 900;
export const USER_LOGOUT_REMAINING_SECONDS = 120;
export const USER_ACTIVITY_CHECK_TIME = 20 * 1000;
export const DEFAULT_EVENTS = ['click', 'mousewheel', 'keydown', 'wheel', 'scroll', 'blur', 'dblclick'];
export const IFRAME_POWER_BI_DEFAULT_EVENTS = [
  'loaded',
  'saved',
  'rendered',
  'saveAsTriggered',
  'dataSelected',
  'buttonClicked',
  'filtersApplied',
  'pageChanged',
  'commandTriggered',
  'swipeStart',
  'swipeEnd',
  'bookmarkApplied',
  'dataHyperlinkClicked',
  'visualRendered',
  'visualClicked',
  'selectionChanged',
  'renderingStarted',
];

export const IFRAME_POWER_BI_DASHBOARD_DEFAULT_EVENTS = [
  'loaded',
  'saved',
  'rendered',
  'saveAsTriggered',
  'dataSelected',
  'buttonClicked',
  'tileClicked',
];

export const IFRAME_TABLEAU_DEFAULT_EVENTS = [
  'marksselection',
  'filterchange',
  'tabswitch',
  'firstinteractive',
  'parametervaluechange',
  'storypointswitch',
  'firstvizsizeknown',
  'markshighlight',
  'customviewsetdefault',
  'customviewload',
  'customviewremove',
  'customviewSave',
  'toolbarstatechange',
  'urlaction',
  'vizresize',
];
