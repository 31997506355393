// use the raw modal that isn't exposed/exported in the index. (Modal from appkit is really a "modal installer" that references the raw modal itself)
import Modal from 'appkit-react/lib/Modal/Modal';

const OPEN_CLASS = 'a-modal-opened';
const TIME_DURATION = 300;

// Appkit's version constantly removes the OPEN_CLASS from the body if there is a modal that is not visible.
// This adds a check to make sure the modal is changing from visible to not visible before attempting to remove the OPEN_CLASS from the body.
Modal.prototype.componentDidUpdate = function componentDidUpdate(prevProps) {
  const { props } = this;
  const bodyClass = document.body && document.body.classList;
  if (props.visible) {
    // first show
    if (!prevProps.visible) {
      this.openTime = Date.now();
    }
    //use mouse scroll for the modal
    if (bodyClass && !bodyClass.contains(OPEN_CLASS)) {
      bodyClass.add(OPEN_CLASS);
    }

    // The main change. See if it was previously visible before attempting to remove the class
  } else if (prevProps.visible) {
    if (bodyClass) {
      setTimeout(function() {
        bodyClass.remove(OPEN_CLASS);
      }, TIME_DURATION);
    }
  }
};
