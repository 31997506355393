import React from 'react';
import ReactDOM from 'react-dom';
import '_common/appkitPatches';
import 'bootstrap/dist/css/bootstrap.min.css';
// DATADOG
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { PWC_ENV, enabledEnvironment } from './_common/utils/appConfig';

const MOUNT_NODE = document.getElementById('root');

async function renderApp() {
  let App = null;
  localStorage.setItem('Build', process.env.REACT_APP_BUILD_NUMBER);
  if (process.env.REACT_APP_DD_TOKEN_WORKBENCH && process.env.REACT_APP_DD_TOKEN_SKYLIGHT && enabledEnvironment()) {
    datadogRum.init({
      applicationId:
        process.env.REACT_APP_TYPE === 'WORKBENCH'
          ? process.env.REACT_APP_ID_WORKBENCH
          : process.env.REACT_APP_ID_SKYLIGHT,
      clientToken:
        process.env.REACT_APP_TYPE === 'WORKBENCH'
          ? process.env.REACT_APP_DD_TOKEN_WORKBENCH
          : process.env.REACT_APP_DD_TOKEN_SKYLIGHT,
      site: process.env.REACT_APP_SITE,
      env: process.env.ENV || PWC_ENV,
      service: process.env.REACT_APP_SERVICE_NAME,
      version: process.env.REACT_APP_BUILD_NUMBER || 'local build',
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
    datadogLogs.init({
      clientToken:
        process.env.REACT_APP_TYPE === 'WORKBENCH'
          ? process.env.REACT_APP_DD_TOKEN_WORKBENCH
          : process.env.REACT_APP_DD_TOKEN_SKYLIGHT,
      site: process.env.REACT_APP_SITE,
      service: process.env.SERVICE_NAME,
      forwardErrorsToLogs: true,
      sampleRate: 100,
    });
  }

  if (process.env.REACT_APP_TYPE === 'WORKBENCH') {
    const WorkbenchApp = await import('./Workbench/App');
    App = WorkbenchApp.default;
  } else if (process.env.REACT_APP_TYPE === 'SKYLIGHT') {
    const SkylightApp = await import('./Skylight/App');
    App = SkylightApp.default;
  } else if (process.env.REACT_APP_TYPE === 'CATALOGUE') {
    const CatalogueApp = await import('./Catalogue/Catalogue');
    App = CatalogueApp.default;
  }

  ReactDOM.render(<App />, MOUNT_NODE);
}
renderApp();
